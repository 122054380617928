import React, { useEffect, useState, useMemo, useCallback, CSSProperties } from "react";
import {
  Modal,
  Button,
  Icon,
  Dropdown,
  Pagination,
  PaginationProps,
  CheckboxProps,
  Label,
  TextArea,
  Form,
  Input,
  Popup,
} from "semantic-ui-react";
import RenderNameStatus from "./RenderNameStatus";

// UX
import CardSupplyOrderUX from "./CardSupplyOrderUX";
import CardSupplyOrderSearch from "./CardSupplyOrderSearch";
import ModProductPackageAlert from "../TPD/ModProductPackageAlert";
import CardSupplyOrderQA from "react-lib/apps/HISV3/MSD/CardSupplyOrderQA";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";

// Utils
import { formatDatetime } from "react-lib/utils";
import { get_status_color, get_type_color } from "./dispensing/UtilMSD";

import CONFIG from "config/config";

// CSS
import "./CardSupplyOrder.scss";
import { useIntl } from "react-intl";

// if (type == 'HOME_OPD') {
//   result = 'สั่งเวชภัณฑ์สำหรับขายผู้ป่วยนอก'
// } else if (type == 'HOME_IPD') {
//   result = 'สั่งเวชภัณฑ์สำหรับขายผู้ป่วยใน'
// } else if (type == 'STAT') {
//   result = 'สั่งเวชภัณฑ์ฉุกเฉินสำหรับผู้ป่วย'
// } else if (type == 'ONE_DAY') {
//   result = 'สั่งเวชภัณฑ์เพื่อใช้กับผู้ป่วย'
// } else if (type == 'REFILL_OPD') {
//   result = 'บันทึกใช้เวชภัณฑ์ในการรักษาผู้ป่วยนอก'
// } else if (type == 'REFILL_IPD') {
//   result = 'บันทึกใช้เวชภัณฑ์ในการรักษาผู้ป่วยใน'
// } else if (type == 'COST_CENTER') {
// COST_CENTER จะ เบิกไม่ได้ เลย สั่งได้ทันที
//   result = 'บันทึกใช้เวชภัณฑ์เหมารวมในหัตถการ'

// } else {
//   result = ''
// }
type CardSupplyOrderProps = {
  setProp: any;
  onEvent: any;
  // seq
  runSequence: any;
  OrderSupplySequence: any;
  // options
  supplyOrderModeOptions: any[] | undefined;
  supplyOrderEligibilityOptions: any[] | undefined;
  masterOptions: any;
  // CommonInterface
  django?: any;
  errorMessage: any;
  successMessage: any;
  selectedEncounter: any;
  buttonLoadCheck: any;
  // Med Reconcile
  medReconcileCheck?: any;
  divisionType?: any;
  medReconcileIndex?: number;
  // config
  isSupplyOrderWorkflow: boolean;
  // style
  tableStyle?: CSSProperties;
  cardStyle?: CSSProperties;
};

type PriceSummaryType = {
  total: number;
  claimable: number;
  nonClaimable: number;
  discount: number;
};

// Const
const MODE_OPTIONS = [
  {
    key: "EQUIPMENT",
    value: "EQUIPMENT",
    text: "EQUIPMENT",
  },
  {
    key: "SUPPLY",
    value: "SUPPLY",
    text: "SUPPLY",
  },
];

const CLAIM_RESULT = {
  1: "เบิกได้",
  2: "เบิกไม่ได้ เนื่องจากตอบคำถามไม่ครบ",
  3: "เบิกไม่ได้ เนื่องจากตอบคำถามไม่ตรงตามเงื่อนไข",
  4: "เบิกไม่ได้ เนื่องจากประเภทรายการสั่งหรือตัวผลิตภัณฑ์ไม่ตรงเงื่อนไข",
};

const CLAIM_RESULT_ICON = {
  1: { name: "check", color: "green" },
  2: { name: "warning sign", color: "yellow" },
  3: { name: "remove", color: "red" },
  4: { name: "minus", color: "gray" },
};

const MOD_CANCEL = "ModalCalcel";

const OPD_TYPE = ["HOME_OPD", "REFILL_OPD"];

const CARD_SUPPLY_ORDER = "CardSupplyOrder";

const CardSupplyOrder = (props: CardSupplyOrderProps) => {
  const intl = useIntl();
  const [priceSummary, setPriceSummary] = useState<PriceSummaryType>({
    total: 0,
    claimable: 0,
    nonClaimable: 0,
    discount: 0,
  });
  const [needCalculation, setNeedCalculation] = useState<boolean>(false);
  const [openModCancel, setOpenModCancel] = useState(false);
  const [note, setNote] = useState<any>("");

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "OrderSupply", restart: true });

    props.onEvent({ message: "getChoicesSupplyOrderMode", params: {} });
    props.onEvent({ message: "getChoicesSupplyOrderEligibility", params: {} });
    props.onEvent({ message: "getDivisionDetail", params: {} });

    // return () => {
    //   props.setProp("OrderSupplySequence", null);
    // }
  }, [props.selectedEncounter?.id]);

  // Price summary
  useEffect(() => {
    let total: number = 0;
    let claimable: number = 0;
    let nonClaimable: number = 0;
    let discount: number = 0;

    const isCostCenter = props.OrderSupplySequence?.supplyOrder?.type === "COST_CENTER";
    const totalKey = isCostCenter ? "value_total" : "price_total";

    props.OrderSupplySequence?.supplyOrder?.items?.forEach((item: any) => {
      total += item[totalKey] || 0;
      claimable += item.price_claimable || 0;
      nonClaimable += item.price_non_claimable || 0;
      discount += item.price_discount || 0;
    });

    setPriceSummary({
      total,
      claimable,
      nonClaimable,
      discount,
    });
  }, [props.OrderSupplySequence?.supplyOrder]);

  useEffect(() => {
    if (props.OrderSupplySequence?.searchManufacturer?.length >= 2) {
      props.onEvent({
        message: "checkManufacturer",
        params: {
          name_icontains: props.OrderSupplySequence?.searchManufacturer,
        },
      });
    }
  }, [props.OrderSupplySequence?.searchManufacturer]);

  useEffect(() => {
    if (props.successMessage?.[MOD_CANCEL]) {
      setOpenModCancel(false);
    }
  }, [props.successMessage?.[MOD_CANCEL]]);

  // Callback
  const handleEditChange = useCallback((idx: number, key: string) => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "edit",
      idx,
      key,
    });
  }, []);

  const handleChangeValueByIndex = useCallback(
    async (data: { index: number; key: string; value: any }) => {
      const items = props.OrderSupplySequence?.supplyOrder?.items || [];

      items[data.index][data.key] = data.value;

      await props.setProp(`OrderSupplySequence.supplyOrder.items`, [...items]);
    },
    [props.OrderSupplySequence?.supplyOrder?.items]
  );

  // Memo
  const supplyOrderType = useMemo(() => {
    return props.OrderSupplySequence?.supplyOrder?.type || "";
  }, [props.OrderSupplySequence?.supplyOrder?.type]);

  const ribbonColor = useMemo(() => get_type_color(supplyOrderType), [supplyOrderType]);

  const statusColor = useMemo(
    () => get_status_color(props.OrderSupplySequence?.supplyOrder?.status),
    [props.OrderSupplySequence?.supplyOrder?.status]
  );

  // SupplySequence
  const historySupplyOrder = useMemo(() => {
    const items: Record<string, any>[] = props.OrderSupplySequence?.historySupplyOrder || []

    return items.filter((item) => {
      const isOPD = props.selectedEncounter?.type === "OPD";
      const isOTH = props.selectedEncounter?.type === "OTH";
      const isIncludedInOPDType = OPD_TYPE.includes(item.type);

      if (isOTH) {
        return item.type === "HOME_OPD";
      }

      return (
        item.type === "COST_CENTER" ||
        item.type === "STAT" ||
        (isOPD ? isIncludedInOPDType : !isIncludedInOPDType)
      );
    });
  }, [props.OrderSupplySequence?.historySupplyOrder, props.selectedEncounter]);

  const historySupplyOrderItems = useMemo(
    () =>
      historySupplyOrder?.map((item: any, index: number) => ({
        ...item,
        code: <div id={`CardSupplyOrder-Items-Code-${index}`}>{item.code}</div>,
        type: <div id={`CardSupplyOrder-Items-Type-${index}`}>{item.type === "COST_CENTER" ? "Cost center" : item.type}</div>,
        order_time: formatDatetime(item.order_time, false),
        order_div: item?.order_div_name ? item?.order_div_name : item?.order_div,
        order_perform_div: item?.order_perform_div_name
          ? item?.order_perform_div_name
          : item?.order_perform_div,
        order_perform_time: formatDatetime(item.order_perform_time, false),
        status: item?.supply_transfer_status || item?.status,
      })),
    [props.OrderSupplySequence?.historySupplyOrder, historySupplyOrder]
  );

  const editOrderDiv = useMemo(() => {
    return props.masterOptions?.division?.find(
      (options: any) => options.key === props.OrderSupplySequence?.supplyOrder?.order_div
    )?.text;
  }, [props.masterOptions?.division, props.OrderSupplySequence?.supplyOrder]);

  const editOrderPerformDiv = useMemo(() => {
    return props.masterOptions?.division?.find(
      (options: any) => options.key === props.OrderSupplySequence?.supplyOrder?.order_perform_div
    )?.text;
  }, [props.masterOptions?.division, props.OrderSupplySequence?.supplyOrder]);

  const errorMessage = useMemo(() => {
    const errMsg =
      props.errorMessage?.OrderSupply || props.errorMessage?.[CARD_SUPPLY_ORDER]?.error;

    const isDrNotFound = errMsg?.code === "DOCTOR_NOT_FOUND";

    return { code: errMsg?.code, error: isDrNotFound ? null : errMsg };
  }, [props.errorMessage]);

  // SupplyOrder
  const supplyOrderItems = useMemo(() => {
    let payloads = props.OrderSupplySequence?.supplyOrder?.claim_payload?.payloads;

    return props.OrderSupplySequence?.supplyOrder?.items.map((item: any, idx: any) => {
      let msg = (CLAIM_RESULT as any)[item.claim_result];
      let remark = payloads?.find((i: any) => i.product_id === item.product)?.claim_result?.remark;
      msg = (
        <div>
          {msg}
          {typeof item.claim_result_description !== "undefined" ? (
            <>
              <br /> <br /> {item.claim_result_description}
            </>
          ) : null}
          {remark ? (
            <>
              <br /> <br /> {remark}
            </>
          ) : null}
        </div>
      );

      return {
        ...item,
        no: idx + 1,
        name: <RenderNameStatus data={item} />,
        condition: (
          <>
            {!!(CLAIM_RESULT_ICON as any)[item.claim_result] && (
              <Popup
                trigger={
                  <Icon
                    name={(CLAIM_RESULT_ICON as any)[item.claim_result].name}
                    color={(CLAIM_RESULT_ICON as any)[item.claim_result].color}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    onClick={(e: any) => {
                      let supplyQA: any = [];

                      let payload = (payloads || item?.claim_payload?.payloads || []).find(
                        (data: any) => data.product_id === item.product
                      );

                      if (payload) {
                        supplyQA.push({ ...payload, isConfirm: false });
                      }

                      props.setProp("OrderSupplySequence.supplyQA", supplyQA);
                    }}
                  />
                }
                offset={-10}
                content={msg}
                flowing
                hoverable
                position="bottom left"
              />
            )}
          </>
        ),
        quantity_request: (
          <div style={{ width: "100%", height: "100%" }}>
            <Input
              id={`CardSupplyOrder-Input-Quantity-${idx}`}
              ref={(ref: any) => ref && (ref.inputRef.current.style.textAlign = "right")}
              min="0"
              type="number"
              value={item.quantity_request}
              fluid={true}
              onChange={(e, data) => {
                handleChangeValueByIndex({
                  index: idx,
                  key: "quantity_request",
                  value: data.value,
                });

                setNeedCalculation(supplyOrderType !== "COST_CENTER");
              }}
              onBlur={() => {
                handleEditChange(idx, "quantity_request");

                setNeedCalculation(supplyOrderType !== "COST_CENTER");
              }}
            />
          </div>
        ),
        eligibility_type: (
          <div className="dropdown overflow">
            <Dropdown
              id={`CardSupplyOrder-Dropdown-Eligibility-${idx}`}
              fluid
              selection
              upward={false}
              options={props.supplyOrderEligibilityOptions}
              value={
                props.supplyOrderEligibilityOptions?.find(
                  (options: any) => options.key === item.eligibility_type
                )?.value
              }
              onChange={async (e: any, { value }: any) => {
                let key = props.supplyOrderEligibilityOptions?.find(
                  (item: any) => item.value === value
                )?.key;

                await handleChangeValueByIndex({
                  index: idx,
                  key: "eligibility_type",
                  value: key,
                });

                handleEditChange(idx, "eligibility_type");
              }}
            />
          </div>
        ),
        mode: !item.mode_fixed ? (
          <div className="dropdown overflow">
            <Dropdown
              id={`CardSupplyOrder-Dropdown-Mode-${idx}`}
              fluid
              selection
              upward={false}
              options={MODE_OPTIONS}
              value={item.mode}
              onChange={async (e: any, { value }: any) => {
                await handleChangeValueByIndex({
                  index: idx,
                  key: "mode",
                  value,
                });

                handleEditChange(idx, "mode");
              }}
            />
          </div>
        ) : (
          item.mode
        ),
        delete: (
          <Button
            id={`CardSupplyOrder-Button-Delete-${idx}`}
            circular
            icon="trash alternate"
            color="red"
            onClick={() => {
              // items.splice(idx, 1);
              // props.setProp("OrderSupplySequence.supplyOrder.items", [
              //   ...items,
              // ]);
              props.runSequence({
                sequence: "OrderSupply",
                action: "delete",
                item: item,
              });
            }}
          />
        ),
        price_unit:(  <div style={{ textAlign: "end" }}>
            {Number(item.price_unit).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>),
        price_total:(  <div style={{ textAlign: "end" }}>
            {Number(item.price_total).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>),
        price_claimable:(  <div style={{ textAlign: "end" }}>
            {Number(item.price_claimable).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>),
        price_non_claimable:(  <div style={{ textAlign: "end" }}>
            {Number(item.price_non_claimable).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>),
        price_discount:(  <div style={{ textAlign: "end" }}>
            {Number(item.price_discount).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>)

        // price_claimable: item.price_claimable === 0 ? "0.00" : item.price_claimable,
        // price_non_claimable: item.price_non_claimable === 0 ? "0.00" : item.price_non_claimable,
      };
    });
  }, [
    props.supplyOrderEligibilityOptions,
    props.OrderSupplySequence?.supplyOrder?.items,
    props.OrderSupplySequence?.supplyOrder,
  ]);

  // Handler
  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`OrderSupplySequence.supplyOrder.${key}`, v.value);
  };

  const handleApprove = () => {
    const supplyOrder = props.OrderSupplySequence?.supplyOrder;

    props.runSequence({
      sequence: "OrderSupply",
      action: "cancel",
      card: MOD_CANCEL,
      cardKey: CARD_SUPPLY_ORDER,
      pk: supplyOrder?.id || supplyOrder?.pk,
      note: note,
      isSupplyOrderWorkflow: props?.isSupplyOrderWorkflow,
    });
  };

  const handleClose = () => {
    setOpenModCancel(false);
    setNote(null);
    props.setProp(`errorMessage.${MOD_CANCEL}`, null);
  };

  const handleGetTrPropsHistory = (state: any, rowInfo: any) => {
    const original = rowInfo?.original;
    const background =
      original?.id && original?.id === props.OrderSupplySequence?.searchHistorySelected?.id
        ? "blueSelectedRow"
        : "";

    return {
      onClick: () => {
        if (original) {
          props.runSequence({
            sequence: "OrderSupply",
            action: "selectedHistory",
            original: original,
          });
        }
      },
      className: background,
    };
  };

  const handleGetTdPropsSupplyOrder = () => {
    return {
      style: {
        cursor: "pointer",
        overflow: "visible",
      },
    };
  };

  const handlePageChange = (e: any, data: PaginationProps) => {
    if (props.OrderSupplySequence?.historySupplyActivePage !== data.activePage) {
      // Fetch data
      props.runSequence({
        sequence: "OrderSupply",
        action: "searchHistory",
        toActivePage: data.activePage,
      });
    }
  };

  const handleHistorySearch = () => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "searchHistory",
      firstPage: true,
    });
  };

  const handleToggleUseRange = (e: any, data: CheckboxProps) => {
    props.setProp("OrderSupplySequence.isUseRangeSearch", data.checked);
  };

  const handleNewSupplyOrder = async (e: any, data: any) => {
    if (["REFILL_IPD", "REFILL_OPD"].includes(data.name)) {
      const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

      if (isLocked) {
        return;
      }
    }

    setNeedCalculation(data.name !== "COST_CENTER");

    props.runSequence({
      sequence: "OrderSupply",
      action: "new_supply",
      type: data.name,
    });
  };

  const handleChangeQty = (e: any) => {
    let value;

    try {
      value = parseInt(e.target.value);
    } catch {
      value = 0;
    }

    props.setProp("OrderSupplySequence.qty", value);
  };

  const handleAddToOrder = () => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "add",
    });

    setNeedCalculation(true);
  };

  const handleCalc = () => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "calc",
      onSuccess: () => setNeedCalculation(false),
    });
  };

  const handleEdit = () => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "update",
      card: CARD_SUPPLY_ORDER,
      buttonLoadKey: `${CARD_SUPPLY_ORDER}_UPDATE`,
      isSupplyOrderWorkflow: props.isSupplyOrderWorkflow,
    });
  };

  const handleSelectSupplyOrder = () => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "selectedAndAdd",
      orderType: supplyOrderType,
    });

    setNeedCalculation(supplyOrderType !== "COST_CENTER");
  };

  const handleSave = (data: any) => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "save",
      buttonLoadKey: `${CARD_SUPPLY_ORDER}_SAVE`,
      card: CARD_SUPPLY_ORDER,
      skipDoctor: data?.skipDoctor,
    });
  };

  const handleClearMessage = async () => {
    await props.setProp(`errorMessage.${CARD_SUPPLY_ORDER}.error`, null);

    props.runSequence({
      sequence: "OrderSupply",
      action: "clear",
    });
  };

  const handleClosePackageAlert = () => {
    props.setProp("OrderSupplySequence.packageAlertList", []);
  };

  const handlePackageAddToOrder = async (selected: any[]) => {
    const items = selected.map((item) => ({
      ...item,
      mode: "SUPPLY",
      count: item.quantity,
      id: item.supply,
      selected: true,
    }));

    await props.setProp("OrderSupplySequence.supplyList", items);
    await props.setProp("OrderSupplySequence.packageAlertList", []);

    handleSelectSupplyOrder();

    handleClosePackageAlert();
  };

  const handleConfirmDrNotFound = () => {
    handleSave({ skipDoctor: true });

    handleClearMessage();
  };

  return (
    <div id={"CardSupplyOrder"} style={{ padding: "0 10px 2rem", ...(props.cardStyle || {}) }}>
      <SnackMessage
        id="CardSupplyOrder"
        onEvent={props.onEvent}
        onClose={handleClearMessage}
        error={errorMessage.error}
        success={null}
        languageUX={props.languageUX}
      />

      <CardSupplyOrderUX
        // data
        OrderSupplySequence={props.OrderSupplySequence}
        searchHistorySelected={props.OrderSupplySequence?.searchHistorySelected}
        isStatus={props.OrderSupplySequence?.supplyOrder?.status}
        ribbonColor={ribbonColor}
        titleSupplyOrder={supplyOrderType === "COST_CENTER" ? "Cost center" : supplyOrderType}
        supplyOrderType={supplyOrderType}
        editCode={props.OrderSupplySequence?.supplyOrder?.code}
        editEncounter={props.OrderSupplySequence?.supplyOrder?.encounter_number}
        editDoctor={props.OrderSupplySequence?.supplyOrder?.doctor}
        // options
        masterOptions={props.masterOptions}
        editOrderDiv={editOrderDiv}
        editOrderPerformDiv={editOrderPerformDiv}
        selectedOrderDiv={props.OrderSupplySequence?.supplyOrder?.order_div}
        selectedPerform={props.OrderSupplySequence?.supplyOrder?.order_perform_div}
        showAddDivision={["REFILL_OPD", "REFILL_IPD", "COST_CENTER"].includes(
          supplyOrderType || ""
        )}
        styleEditSupply={
          supplyOrderType || props.OrderSupplySequence?.searchHistorySelected
            ? { display: "block" }
            : { display: "none" }
        }
        type={props?.selectedEncounter?.type}
        mainQuatityDisabled={!props.OrderSupplySequence?.selectedSupply}
        searchSupply={props.OrderSupplySequence?.searchSupply || ""}
        showSupplyList={false}
        qty={props.OrderSupplySequence?.qty || 0}
        supplyOrderItems={supplyOrderItems || []}
        historySupplyOrder={historySupplyOrderItems || []}
        priceTotal={priceSummary.total}
        priceClaimable={priceSummary.claimable}
        priceNonClaimable={priceSummary.nonClaimable}
        priceDiscount={priceSummary.discount}
        tableStyle={props.tableStyle}
        opdHomeDisplay={["OPD", "OTH"].includes(props.selectedEncounter?.type || "")}
        opdRefillDisplay={
          CONFIG.RAKSTHAI_SUPPLY_ORDER_HOMEOPD ? false : props?.selectedEncounter?.type === "OPD"
        }
        ipdRefillDisplay={props?.selectedEncounter?.type === "IPD"}
        ipdHomeDisplay={props?.selectedEncounter?.type === "IPD"}
        ipdOneDayDisplay={props?.selectedEncounter?.type === "IPD"}
        isStatDisplay={["IPD", "OPD"].includes(props.selectedEncounter?.type || "")}
        costCenterDisplay={
          CONFIG.RAKSTHAI_SUPPLY_ORDER_HOMEOPD
            ? false
            : ["IPD", "OPD"].includes(props.selectedEncounter?.type || "")
        }
        status={props.OrderSupplySequence?.supplyOrder?.status}
        // config
        isEdit={props.isEdit}
        isCostCenter={supplyOrderType === "COST_CENTER"}
        // callback
        getTrPropsHistory={handleGetTrPropsHistory}
        handleChangeValue={handleChangeValue}
        handleHistorySearch={handleHistorySearch}
        // ประวัติ การสั่งเวชภัฒฑ์
        handleToggleUseRange={handleToggleUseRange}
        handleToHistory={(a: any) => {
          props.setProp("OrderSupplySequence.searchToHistorySearch", a);
        }}
        handleFromHistory={(a: any) => {
          props.setProp("OrderSupplySequence.searchFromHistorySearch", a);
        }}
        onNewSupplyOrder={handleNewSupplyOrder}
        search={() => {
          props.runSequence({
            sequence: "OrderSupply",
            action: "search",
            orderType: supplyOrderType,
          });
        }}
        onChangeSearchSupply={(e: any) =>
          props.setProp("OrderSupplySequence.searchSupply", e.target.value)
        }
        getTdPropsSupplyOrder={handleGetTdPropsSupplyOrder}
        // Need to handle validation
        onChangeQty={handleChangeQty}
        addToOrder={handleAddToOrder}
        onCancel={() => setOpenModCancel(true)}
        onCalc={handleCalc}
        // Element
        paginationComponent={
          props.OrderSupplySequence?.historySupplyTotalPage > 0 ? (
            <Pagination
              activePage={props.OrderSupplySequence?.historySupplyActivePage}
              size="mini"
              totalPages={props.OrderSupplySequence?.historySupplyTotalPage}
              onPageChange={handlePageChange}
            />
          ) : null
        }
        buttonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEdit}
            // data
            paramKey={`${CARD_SUPPLY_ORDER}_UPDATE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_ORDER}_UPDATE`]}
            size="medium"
            // config
            id="CardSupplyOrder-ButtonLoadCheck-Update"
            name="UPDATE"
            title={intl.formatMessage({ id: "แก้ไขรายการสั่งจ่ายเวชภัณฑ์" })}
            color="yellow"
            disabled={needCalculation}
            style={{ minWidth: "max-content", width: "100%" }}
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_SUPPLY_ORDER}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SUPPLY_ORDER}_SAVE`]}
            size="medium"
            // config
            id="CardSupplyOrder-ButtonLoadCheck-Save"
            name="SAVE"
            title={intl.formatMessage({ id: "บันทึก" })}
            color="green"
            disabled={needCalculation}
            style={{
              minWidth: "max-content",
              width: "100%",
            }}
          />
        }
        // Label
        statusSupplyOrder={
          props.OrderSupplySequence?.supplyOrder?.status ? (
            <Label id="CardSupplyOrder-Label-OrderStatus" color={statusColor}>
              {" "}
              {props.OrderSupplySequence?.supplyOrder?.status === "PRINTED"
                ? props.OrderSupplySequence?.searchHistorySelected?.supply_transfer_status
                : props.OrderSupplySequence?.supplyOrder?.status}{" "}
            </Label>
          ) : null
        }
        languageUX={props.languageUX}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={openModCancel}
        titleName={intl.formatMessage({ id: "ยืนยันยกเลิก ?" })}
        onDeny={handleClose}
        onCloseWithDimmerClick={handleClose}
        onApprove={handleApprove}
        content={
          <div style={{ margin: "-1rem 0px -1.5rem" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              <ErrorMessage error={props?.errorMessage?.[MOD_CANCEL]?.error} />
            </div>
            <div>{intl.formatMessage({ id: "สาเหตุ" })}</div>
            <Form>
              <TextArea
                style={{
                  width: "100%",
                  height: "100%",
                  border: "solid #cccccc 1px",
                  marginTop: "5px",
                }}
                rows={4}
                value={note}
                onChange={(e, v) => setNote(v.value)}
              />
            </Form>
          </div>
        }
      />

      <Modal
        size={CONFIG.ENABLE_PRICE_LEVEL ? "fullscreen" : "large"}
        open={props.OrderSupplySequence?.showSupplyList > 0}
        closeOnDimmerClick={true}
        style={{ left: "unset !important" }}
        onClose={() => props.setProp("OrderSupplySequence.showSupplyList", false)}
      >
        <CardSupplyOrderSearch
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          OrderSupplySequence={props.OrderSupplySequence}
          // options
          supplyOrderModeOptions={props.supplyOrderModeOptions}
          // callback
          onSelected={handleSelectSupplyOrder}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModProductPackageAlert
        onEvent={props.onEvent}
        // data
        items={props.OrderSupplySequence?.packageAlertList}
        type="supply"
        // callback
        onClose={handleClosePackageAlert}
        onAddToOrder={handlePackageAddToOrder}
        languageUX={props.languageUX}
      />

      {props.OrderSupplySequence?.supplyQA
        ?.filter((i: any, idx: number) => idx === 0)
        ?.map((item: any, index: any) => {
          return (
            <>
              <CardSupplyOrderQA
                supplyQA={item}
                // index={index}
                runSequence={(data: any) => {
                  if (data?.action === "answerSave" && data?.supplyData) {
                    // setNeedCalculation(true)
                  }
                  props.runSequence(data);
                }}
                setProp={props.setProp}
                languageUX={props.languageUX}
              />
            </>
          );
        })}

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />

      <ModConfirm
        openModal={errorMessage.code === "DOCTOR_NOT_FOUND"}
        textContent={"ไม่พบแพทย์เจ้าของไข้ ต้องการสั่งโดยไม่ระบุแพทย์หรือไม่?"}
        titleColor="yellow"
        onApprove={handleConfirmDrNotFound}
        onCloseWithDimmerClick={handleClearMessage}
        onDeny={handleClearMessage}
      />
    </div>
  );
};

CardSupplyOrder.displayName = "CardSupplyOrder";

export default React.memo(CardSupplyOrder);
